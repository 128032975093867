<template>
	<div>
		<H5header></H5header>

		<div class="nav">
			<div @click="getnav(1)" :class="navIndex == 1 ? 'navselect' : ''">北京竞技场</div>
			<div @click="getnav(2)" :class="navIndex == 2 ? 'navselect' : ''">专题专栏</div>
		</div>
		<div class="xians"></div>

		<div v-if="navIndex == 1">
			<div class="articlelist" v-for="(item, index) in data1" :key="index" @click="jump(item)">
				<div class="imgbox">
					<div class="title-img">{{ item.name }}</div>
					<img :src="item.img_url" alt="" v-if="item.img_url">
					<img src="../../assets/image/placeholder.png" alt="" v-else>
				</div>
				<div class="date">{{ item.create_time | format }}</div>
				<div class="xian"></div>
			</div>
		</div>

		<div v-if="navIndex == 2">
			<div class="articlelist" v-for="(item, index) in data2" :key="index" @click="jump(item)">
				<div class="imgbox">
					<div class="title-img">{{ item.name }}</div>
					<img :src="item.img_url" alt="" v-if="item.img_url">
					<img src="../../assets/image/placeholder.png" alt="" v-else>
				</div>
				<div class="date">{{ item.create_time | format }}</div>
				<div class="xian"></div>
			</div>
		</div>

	</div>
</template>

<script>
import H5header from "./components/header3.vue"
export default {
	name: '',
	components: {
		H5header,
	},
	data() {
		return {
			data1: [],
			data2: [],
			navIndex: 1
		}
	},
	filters: {
		format(x) {
			if (x != null && x != '') {
				return x.split(" ")[0]
			}
		}
	},
	created() {
		this.findList()
	},
	methods: {
		findList() {
			this.$api.bjSport(744, 0).then(res => {
				for (let i in res.data.data) {
					if (res.data.data[i].isColumn == 0) {
						this.data1.push(res.data.data[i])
					}
					if (res.data.data[i].isColumn == 1) {
						this.data2.push(res.data.data[i])
					}
				}
			})
		},
		jump(item) {
			if (item.bind_theme == 0) {
				window.open(item.link_address, '_blank');
			} else {
				this.$router.push({
					path: '/h5/' + item.link_address,
					query: {
						logo: item.img_url,
						title: item.name,
						refresh: new Date().getTime(),
					}
				})
			}
		},
		getnav(e) {
			this.data1 = []
			this.data2 = []
			this.findList()
			this.navIndex = e
		},
	}
}
</script>

<style scoped lang="scss">
.articlelist {

	.title {
		height: 36PX;
		font-size: 14PX;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin: 17PX 22PX 0 22PX;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.date {
		font-size: 11PX;
		font-family: Source Han Sans CN;
		font-weight: 400;
		margin-top: 5PX;
		margin-right: 23PX;
		text-align: right;
		margin-bottom: 12PX;
	}


	.imgbox {
		margin: 17PX 22PX 0 22PX;
		display: flex;
		justify-content: space-between;

		img {
			width: 114PX;
			height: 70PX;
			border-radius: 10PX;
			flex-shrink: 0;
		}

		.title-img {
			height: 73PX;
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			line-clamp: 4;
			-webkit-box-orient: vertical;
			margin-right: 10PX;
		}
	}
}

.xian {
	width: 100%;
	height: 4PX;
	background: #F4F8FB;
}

.nav {
	display: flex;
	justify-content: center;
	margin-top: 27PX;

	div {
		width: 42%;
		height: 35PX;
		background: #E8F2FF;
		border-radius: 20PX 20PX 0PX 0PX;
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 15PX;
		color: #437DCA;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16PX;
		cursor: pointer;
	}
}

.xians {
	width: 100%;
	height: 2PX;
	background: #85B0E9;
	border-radius: 1PX;
	margin-bottom: 10PX;
}

.navselect {
	background: linear-gradient(90deg, #6FB2FF, #095FFF) !important;
	box-shadow: 0PX 7PX 16PX 0PX rgba(50, 129, 255, 0.51);
	border-radius: 20PX 20PX 0PX 0PX;
	color: #ffffff !important;
}
</style>