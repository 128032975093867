import { render, staticRenderFns } from "./header3.vue?vue&type=template&id=474f9cfc&scoped=true&"
import script from "./header3.vue?vue&type=script&lang=js&"
export * from "./header3.vue?vue&type=script&lang=js&"
import style0 from "./header3.vue?vue&type=style&index=0&id=474f9cfc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f9cfc",
  null
  
)

export default component.exports